/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';


const HeadlessWordPressDeveloperPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
        >
            <SEO
                title="Headless WordPress Developer"
                description="I pair WordPress with modern JavaScript technologies to create fast, secure and accessible websites."
                imageAlt="Headless WordPress Developer"
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="Headless WordPress Developer, Tom Hirst"
                imageCaption="Headless WordPress Developer, Tom Hirst"
            >
                <h1>Headless WordPress Developer</h1>
                <p className="lead">I pair WordPress with modern JavaScript technologies to create fast, secure and accessible websites.</p>
                <p>Are you looking for someone who can team WordPress with:</p>
                <ul className="check-list check-list-inline">
                    <li>
                        <FontAwesomeIcon icon="check" />
                        <a href="https://reactjs.org/">React</a>
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        <a href="https://www.gatsbyjs.org/">Gatsby</a>
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        <a href="https://nextjs.org/">Next.js</a>
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        <a href="https://frontity.org/">Frontity</a>
                    </li>
                </ul>
                <p>
                    <strong>I can do this for you.</strong> Here&apos;s what
                    you can do next:
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#find-out-more" icon="arrow-down" text="Find out more" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="find-out-more">What is a headless WordPress developer?</h2>
                <p>A headless WordPress developer is someone who takes a modern approach to building websites on WordPress.</p>
                <p>This approach can be defined as using WordPress as the back end and data source for a website alongside a JavaScript front end.</p>
                <p>Common front end frameworks used in headless WordPress development include <a href="https://reactjs.org/">React</a>, <a href="https://www.gatsbyjs.org/">Gatsby</a>, <a href="https://nextjs.org/">Next.js</a> and  <a href="https://frontity.org/">Frontity</a>.</p>
                <h2>Why take a headless WordPress development approach?</h2>
                <p>Everyone knows how great WordPress is.</p>
                <p>It&apos;s been around a long time, familiar to millions and makes it easy for people to manage their website&apos;s content.</p>
                <p>There are however, things that WordPress doesn&apos;t do as well out of the box.</p>
                <p>Choosing a headless WordPress approach for your website helps to solve these things.</p>
                <p>Performance, security and accessibility capabilities are all enhanced.</p>
                <p>Who doesn&apos;t want a fast and safe WordPress website that&apos;s easy for <em>everyone</em> to use?</p>
                <h2>Why hire me as your headless WordPress developer?</h2>
                <p>I&apos;ve been working with WordPress for over a decade.</p>
                <p>Since modern web developments&apos; move towards JavaScript - and WordPress&apos; via Gutenberg - my primary focus
                    has been to discover ways to pair both tools to deliver best in class websites for my clients.</p>
                <p>As a seasoned remote worker, I have delivered successful projects for clients in the UK, USA, Europe, Australia and beyond.</p>
                <p>I come well trusted and recommended having partnered on WordPress solutions for the likes of the NHS, Facebook, TGI Fridays, Umbro and BMW.</p>
                <p>WordPress VIP agencies hire me for my WordPress and JavaScript knowledge on a development and consultancy basis.</p>
                <p>I don&apos;t just work with big brands and VIP agencies!</p>
                <p>I&apos;m happy to discuss all headless WordPress development projects, large or small.</p>
                <h2>Can you make my existing WordPress site headless?</h2>
                <p>Yes. I can take your existing WordPress website and build a modern front end for it that will have all of the same benefits as a from scratch headless WordPress project.</p>
                <h2 id="hire-me">Ready to start your headless WordPress project?</h2>
                <p>
                    Give your project the best chance of success by hiring me.
                    We can move forward with this today. Fill out the form below to tell me more:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have a headless WordPress project needing expert attention."
                    pathname={ location && location.pathname }
                />
                <h2>I&apos;ve previously partnered with</h2>
                <ClientList />
                <h2>I&apos;m highly recommended</h2>
                <div className="blockquote-list-with-middle">
                    <Blockquote
                        quote="I have had the pleasure of working with Tom over the last year. Both in projects for clients and in product development Tom has been a great asset, solving tasks with precision and quality. We have appreciated his WordPress, JavaScript and React skills, plus his technical advice. I can really recommend working with Tom."
                        cite="Henning Hovland, CTO at Dekode"
                        imageName="hh-headshot.jpg"
                        imageAlt="Henning Hovland"
                        boxed
                    />
                </div>
                <h2>Let&apos;s start your headless WordPress project today</h2>
                <a className="button is-green has-icon" href="#hire-me">
                    I&apos;m ready, take me to the form
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

HeadlessWordPressDeveloperPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

HeadlessWordPressDeveloperPage.defaultProps = {
    location: {},
};

export default HeadlessWordPressDeveloperPage;
